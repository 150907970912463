export default ({ fetchMock, delay, mock, toSuccess, toError }) => {
  return {
    '/api/user/menu': options => toSuccess([
      {
        name: '控制面板',
        icon: 'DashboardOutlined',
        path: '/dashboard',
      },
      {
        name: '矿工管理',
        icon: 'RocketOutlined',
        path: '/miners',
        children: [
          {
            name: '矿工列表',
            path: '/miners/list',
          },
          {
            name: '机房列表',
            path: '/engineRooms/list',
          },
          {
            name: '收益列表',
            path: '/miningEarnings/list',
          },
          {
            name: '转账列表',
            path: '/miningTransfers/list',
          },
          {
            name: '每日矿工快照',
            path: '/dailyChainActors/list',
          },
        ],
      },
      {
        name: '运营管理',
        icon: 'GlobalOutlined',
        path: '/yunying',
        children: [
          {
            name: '商户列表',
            path: '/merchants/list/:children?',
          },
          {
            name: '扩容请求',
            path: '/powers/list',
          },
          {
            name: '商户订单',
            path: '/orders/list',
          },
          {
            name: '人工入账',
            path: '/walletManualRequestLogs/list',
          },
          {
            name: '链上充提',
            path: '/walletChainRequestLogs/list',
          },
        ],
      },
      {
        name: '分币管理',
        icon: 'MoneyCollectOutlined',
        path: '/distributes',
        children: [
          {
            name: '分币列表',
            path: '/distributes/list',
          },
          {
            name: '全网分币收益',
            path: '/blockchainOverviews/list',
          },
        ],
      },
      {
        name: '报表管理',
        icon: 'MoneyCollectOutlined',
        path: '/reports',
        children: [
          {
            name: '用户资产',
            path: '/reports/members',
          },
          {
            name: '订单报表',
            path: '/reports/orders',
          },
          {
            name: '每日分币',
            path: '/reports/incomes',
          },
          {
            name: '每日释放',
            path: '/reports/releases',
          },
          {
            name: '燃气报表',
            path: '/reports/gas',
          },
        ],
      },
    ], 400)
  }
}